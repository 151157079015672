<template>
  <div>
    <v-file-input
      accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      :label="$t('commons.file.description')"
      :placeholder="$t('commons.file.title')"
      persistent-hint
      :hint="
        $t('commons.file.helper', {
          size: $utils.humanFileSize(size),
        })
      "
      small-chips
      append-icon="mdi-file-excel-outline"
      prepend-icon=""
      truncate-length="50"
      :error-messages="fileErrors"
      v-model="file"
      show-size
      @input="delayTouch($v.file)"
      @blur="$v.file.$touch"
    ></v-file-input>
  </div>
</template>

<script>
import FormDelayTouchMixin from "./../../mixins/commons/form-delay-touch";
import { required, helpers } from "vuelidate/lib/validators";
export default {
  mixins: [FormDelayTouchMixin],
  props: {
    size: {
      type: Number,
      default: 5242880,
    },
  },
  data: () => ({
    file: null,
  }),
  validations() {
    return {
      file: {
        required,
        size: (value) => {
          if (!helpers.req(value)) return true;
          return value.size < this.size;
        },
        extension: (value) => {
          if (!helpers.req(value)) return true;
          return (
            [
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/vnd.ms-excel",
            ].includes(value.type) &&
            ["xlsx", "xls"].includes(value.name.split(".").pop())
          );
        },
      },
    };
  },
  watch: {
    file: {
      handler() {
        this.$emit('file-changed', this.file)
      },
      deep: true,
    },
  },
  methods: {
    isValid() {
      this.$v.file.$touch();
      return !this.$v.file.$invalid;
    },
    getFile() {
      return this.file;
    },
    resetFile() {
      this.file = null;
      this.$v.file.$reset();
    }
  },
  computed: {
    fileErrors() {
      const errors = [];

      if (!this.$v.file.$dirty) return errors;
      !this.$v.file.required &&
        errors.push(this.$t("resellers.fields.file.required"));

      !this.$v.file.size &&
        errors.push(
          this.$t("resellers.fields.file.invalidSize", {
            size: this.$utils.humanFileSize(this.size),
          })
        );

      !this.$v.file.extension &&
        errors.push(
          this.$t("resellers.fields.file.invalidFormat", {
            size: this.$utils.humanFileSize(this.size),
          })
        );

      return errors;
    },
  },
};
</script>