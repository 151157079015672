<template>
  <div class="mb-4 mt-n5 mx-n5">
    <v-card dark class="mt-0 mb-0" rounded="0">
      <v-card-text class="py-0 px-0">
        <div class="text-body-1 font-weight-medium third--text">
          <v-breadcrumbs class="breadcrumbs" dark :items="items">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                :to="item.to"
                :disabled="item.disabled"
                :exact="item.exact"
              >
                <span :class="{ 'third--text': !item.disabled }">{{
                  item.text
                }}</span>
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data: () => ({})
}
</script>